<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>

        <div class="pa-3">
            <v-toolbar>
            <v-toolbar-title>Report - {{this.form.rc_category}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
            </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark class="primary mr-2" @click="refreshDataTable"><v-icon>mdi-refresh</v-icon></v-btn>         
        </v-card-title>
        <v-card-text>

        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-text>

        <v-data-table
        v-model="selected"  
        ref="vuetable"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait" 
        @click:row="openDetail"   
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>
//import axios from 'axios';
import DlgMessage from '@/components/DlgMessage'
import ApReportService from "@/services/ApReportService"
export default {
    components:{DlgMessage},
    props: {
       caller: String
    },
    data() {
        return{
            //breadcrumb
            items: [
              {text:'Report',disabled: false,href:'/',},
              {text:'List',disabled: false,href:'#',},
              {text:'View',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Seq No.",value: "seq_no"  },
                { text:"Code", value:"rc_code" },
                { text:"Name", value:"rc_name" },
                { text:"Description", value:"rc_description" }
            ],

            tblBody:[],
            tblSearch:'',
            loadingFlag : false,
            errorFlag : false,
            visible:false,
            routeType:"",
            reportDetail:{name:""},
            form:{
                rc_category:"",
                report_display_id:"1"
            },
            routeParam:[],

        }
    },
    async created() {  
        this.form.rc_category = this.$route.params.rc_category;
        
        this.form.report_display_id = this.$route.params.report_display_id;
        this.refreshDataTable();
    },
    mounted(){
        
    },
    watch:{
        "$route.params.rc_category":{
            handler:function(value){
                this.form.rc_category = value;
                this.refreshDataTable();
                //console.log(value);
            },
            deep:true,
            immediate:true,
        }
    },
    // beforeRouteEnter(to,from){
    //     //console.log("to",to.params.log_for)
    //     //console.log("from",from.params.log_for)
    //     this.form.log_for = to.params.log_for;
    //     this.refreshDataTable();
    //     //console.log(to.params.log_for);

    // },    
    // beforeRouteUpdate(to,from){
    //     //console.log("to",to.params.log_for)
    //     //console.log("from",from.params.log_for)
    //     this.form.log_for = to.params.log_for;
    //     this.refreshDataTable();
    //     //console.log(to.params.log_for);

    // },
    methods:{
            async refreshDataTable(){
                this.loadingFlag = true;
                let title = "Report"
                try{
                    ApReportService.list_component(this.form).then((res) => {
                        //console.log(res);
                        this.tblBody = res.data.data;
                        this.logDetail = res.data.rcDetail;

                        // if(res.data.data.length>0){
                        //     this.tblHeaders = [];
                        //     let keys = Object.keys(res.data.data[0]);
                        //     for(let key=0;key<=keys.length;key++){
                        //         let tmp = {text:keys[key],value:keys[key]}
                        //         this.tblHeaders.push(tmp);
                        //     }
                        // }
                        this.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log('e content:',e);
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,e);
                        this.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                  
                }           
            },
        dlgClose(){
            this.visible = false;        
        },
        getIndex(e){
            this.searchKeyIndex = this.searchKeyList.map(function (key) { return key.code; }).indexOf(e);
        },

        openDetail(obj){
            let routeData = this.$router.resolve({ name: 'reports-details', 
                params: { 
                    report_display_id: obj.report_display_id,
                    report_component_id: obj.report_component_id,
                } 
            })
            window.open(routeData.href,'_blank')

        },



    }

}
</script>