import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApSettingService {


    list_component(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_report/list_component", form);

    }
    
    view_component(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_report/view_component", form);

    }

    generate(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_report/generate", form);

    }



}

    
 

export default new ApSettingService();